.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 150px;
  height: auto;
}

:root {
  --primary-color: rgb(25, 103, 44) !important;
  --secondary-color: rgb(233, 245, 219) !important;
}

.green {
  color: var(--primary-color);
}

.font-dancing-script {
  font-family: Dancing Script;
}

.font-dancing-script-header {
  font-family: Dancing Script;
  font-size: 55px;
  font-weight: 700;
}

@media (max-width: 992px) {
  .font-dancing-script-header {
    font-size: 35px;
  }
}

.navigation {
  border: 1px solid var(--primary-color);
  border-radius: 50px;
}

@media (max-width: 992px) {
  .navigation {
    border: none !important;
  }
}

.background-image-section-1 {
  background-image: url("images/background/aloe-vera-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 800px;
}

.header-product {
  border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.product-bg {
  background-color: var(--secondary-color);
}

.link-style-button {
  color: white;
  background-color: var(--primary-color);
  padding: 10px 70px;
  border-radius: 30px;
}

.link-style-button-product {
  color: black;
  /* background-color: var(--primary-color); */
  padding: 15px 70px;
  border-radius: 30px;
  border: 1px solid black;
}

@media (max-width: 991px) {
  .link-style-button-product {
    padding: 15px 15px;
  }
}

.customer-bg {
  background-color: rgb(242, 242, 242);
}

.customer-image {
  height: 280px;
}

.footer-bb {
  border-bottom: 1px solid black;
}

.products {
  background-image: url("images/background/prducts-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
  background-attachment: fixed;
}

.business-cooperation {
  background-image: url("images/background/business-cooperation.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 700px;
  background-attachment: fixed;
}

.link-style-button-bus-cooperation {
  color: black;
  background-color: var(--secondary-color);
  padding: 15px 70px;
  border-radius: 30px;
  border: 1px solid black;
  opacity: 0.75;
}

.business-cooperation {
  background-image: url("images/background/business-cooperation.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 700px;
  background-attachment: fixed;
}

.contact-bg-img {
  background-image: url("images/background/contact-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 700px;
  background-attachment: fixed;
}

.contact-bg {
  background-color: white;
}

.icon-contact {
  color: var(--primary-color);
  font-size: 24px;
}

.icon-contact-big {
  color: var(--primary-color);
  font-size: 36px;
}

.border-radius-20 {
  border-radius: 20px;
}

.form-input-fields {
  margin-bottom: 40px;
  border: none;
  border-bottom: 1px solid var(--primary-color);
  width: -webkit-fill-available;
}

.btn-form-submit {
  border: none;
  background-color: transparent;
  color: var(--primary-color);
}

.navigation-font {
  font-size: 36px !important;
  font-weight: 700 !important;
  color: var(--primary-color) !important;
}

.btn-menu-mobile {
  border: none !important;
  font-size: 24px;
}

@media (min-width: 992px) and (max-width: 2300px) {
  .pading-right-products {
    padding-right: 250px !important;    
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}

.back-to-top-button {
  border: none;
  outline: none;
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  /* border-radius: 55%; */
  font-size: 30px;  
  transition: opacity 0.3s ease-in-out;
}

/* .fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
  background-color: white; 
  transition: all ease-in-out;
} */

.navigation {
  transition: all 0.3s ease-in-out;
}

.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
  background-color: white; 
  transition: all 0.1s ease-in-out;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
}